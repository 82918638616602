<template>
  <div class="base-virtual-list">
    <v-virtual-scroll
      ref="container"
      :bench="bench"
      :items="computedItems"
      :item-height="72"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #default="{ item }">
        <component :is="item.type" v-bind="item.props" v-on="item.listeners" />
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { genListLoader, genListItemMessage } from '@/utils/list-generators';

export default {
  name: 'BaseVirtualList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    bench: {
      type: [Number, String],
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    noMoreData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedItems() {
      if (this.loading) {
        return [...this.items, genListLoader()];
      } else if (this.noMoreData) {
        return [...this.items, genListItemMessage({ message: 'No more data' })];
      }
      return this.items;
    }
  },
  activated() {
    // fix for force rerender
    this.$refs.container.onScroll();
  },
  methods: {
    // for outside scroll
    scrollToBottom() {
      const list = this.$refs.container.$el;
      list && list.scrollTo({ top: list.scrollHeight });
    }
  }
};
</script>

<style lang="sass" scoped>
.base-virtual-list
  overflow: auto
</style>
